<template>
    <span>
         <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              text 
              color="error"
              small
              @click="getResolucion()"
              v-bind="attrs"
              v-on="on"
              :loading="loading"
            >
              <v-icon>
              mdi-file-pdf-box
              </v-icon>
              <slot name="titulo-boton">
              </slot>
            </v-btn>
          </template>
          <span>Ver</span>
        </v-tooltip>
         <v-dialog v-if="dialog" v-model="dialog" max-width="90vh">
          <v-card>
            <v-card-title class="subtitle-2 grey lighten-2">
             {{ titulo }}
            </v-card-title>
            <v-card-text class="pt-4 pb-0">
              <div id="previewDocumento"></div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="dialog = false"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </span>
</template>
<script>
import PDFObject from "@/../paquetes_externos/pdfobject.min";
export default {
    props:{
        id:Number,
        titulo:{
          type:String,
          default:'Resolucion Sanción'
        },
        url:{
          type:String,
          default:''
        }
    },
    data(){
        return{
            loading:false,
            dialog:false
        }
    },
    methods:{
        getResolucion(){
            this.loading = true
            this.$http.get(this.url).then(response=>{
                 setTimeout(async () => {
                    await this.showDocumento(response.data.data.pdf,'#previewDocumento',true);
                }, 100);
                this.dialog=true
                this.loading = false
            }).catch(error=>{
                console.log('------------------------------------');
                console.log(error);
                console.log('------------------------------------');
                this.loading = false
            })
        },
        showDocumento(pdf,id, base64) {
            const options = {
                height: "400px",
                pdfOpenParams: {
                pagemode: "thumbs",
                navpanes: 0,
                toolbar: 1,
                statusbar: 1,
                view: "FitH"
                }
            };
            if(base64){
                pdf='data:application/pdf;base64,'+pdf
            }
            PDFObject.embed(pdf, id, options);  
        }
    }
}
</script>